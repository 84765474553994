import moment from 'moment';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { formatMoneyPT } from '../../lib/masks';
import t from '../../lib/translations';

const CustomTooltip = ({ active, payload, label }: any): JSX.Element => {
  if (payload && active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${moment(label).format('MMMM')}`}</p>
        <p className="label">{`${payload[0]?.name}: ${payload[0]?.value}   `} </p>
        <p className="label">{`${payload[1]?.name}: ${formatMoneyPT(payload[1]?.value)}`} </p>
      </div>
    );
  }

  return null;
};

const formatLegend = (value, entry): JSX.Element => {
  const { color } = entry;

  return <span style={{ color }}>{value}</span>;
};

const MonthlyChart = ({ data }): JSX.Element => {
  const formatter = (data): any => `R$ ${formatMoneyPT(data)}`;
  const dateformatter = (data): string => `${moment(data).format('MMM')}`;

  return (
    <ResponsiveContainer height={200}>
      <BarChart
        width={1600}
        height={200}
        data={data}
        margin={{ top: 20, left: 0, right: 0, bottom: 5 }}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          dataKey="from"
          tickFormatter={dateformatter}
          tick={{ fontSize: '12px', fontWeight: 'bold' }}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="#e3661f"
          tick={{ fontSize: '12px' }}
          allowDecimals={false}
        />
        <YAxis
          yAxisId="right"
          width={100}
          orientation="right"
          stroke="#29654f"
          tickFormatter={formatter}
          tick={{ fontSize: '12px' }}
        />
        <Tooltip
          wrapperStyle={{
            backgroundColor: '#1a202c',
            fill: '#cbd5e0',
            fontSize: '14px',
            borderRadius: '5px',
            paddingLeft: '2px',
          }}
          content={<CustomTooltip />}
        />
        <Legend wrapperStyle={{ fontSize: '12px' }} formatter={formatLegend} />
        <Bar yAxisId="left" dataKey="quantity" name={t.TRANSACTIONS} fill="#e3661f" />
        <Bar yAxisId="right" dataKey="sum" name={t.AMOUNT} fill="#29654f" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyChart;
