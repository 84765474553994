import React from 'react';
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { formatMoneyPT } from '../../lib/masks';
import t from '../../lib/translations';

const CustomTooltip = ({ active, payload, label }: any): JSX.Element => {
  if (payload && active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p className="label">{`${t.TOTAL}: R$ ${formatMoneyPT(payload[0]?.payload.sum)}`} </p>
        <p className="label">{`${t.TRANSACTIONS}: ${payload[0]?.payload.quantity}`} </p>
        <p className="label">{`Média Mensal: R$ ${formatMoneyPT(payload[0]?.payload.average)}`} </p>
      </div>
    );
  }

  return null;
};

const formatLegend = (value, entry): JSX.Element => {
  const { color } = entry;

  return <span style={{ color, fontSize: 12 }}>{value}</span>;
};

const ClientChart = ({ data }): JSX.Element => {
  return (
    <ResponsiveContainer width="95%" height={500}>
      <ComposedChart layout="vertical" width={350} height={500} data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" tick={{ fontSize: '12px', fontWeight: 'bold' }} />
        <YAxis dataKey="farmerName" type="category" interval={0} width={120} />
        <Tooltip
          wrapperStyle={{
            backgroundColor: '#1a202c',
            fill: '#cbd5e0',
            fontSize: '14px',
            borderRadius: '5px',
            paddingLeft: '2px',
          }}
          content={<CustomTooltip />}
        />
        <Legend wrapperStyle={{ fontSize: '12px' }} formatter={formatLegend} />
        <Bar dataKey="average" barSize={10} fill="#e3661f" stroke="#e3661f" name="Média" />
        <Bar dataKey="sum" barSize={10} fill="#29654f" name={t.TRANSACTIONS} />
        {data.length > 0 && (
          <Brush
            dataKey="sum"
            height={25}
            stroke="#00C49F"
            endIndex={data?.length >= 10 ? 10 : 0}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ClientChart;
