import moment from 'moment';
import React from 'react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { formatMoneyPT } from '../../lib/masks';
import t from '../../lib/translations';

const CustomTooltip = ({ active, payload, label }: any): JSX.Element => {
  if (payload && active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${moment(label).format('DD-MMM')}`} </p>
        <p className="label">{`${payload[0]?.name}: ${payload[0]?.value}  `} </p>
        <p className="label">{`${payload[1]?.name}: ${formatMoneyPT(payload[1]?.value)}`} </p>
      </div>
    );
  }

  return null;
};

const formatLegend = (value, entry): JSX.Element => {
  const { color } = entry;
  return <span style={{ color }}>{value}</span>;
};

const DailyChart = ({ data }): JSX.Element => {
  const formatter = (data): any => `R$ ${formatMoneyPT(data)}`;
  const dateformatter = (data): any => `${moment(data).format('DD')}`;

  return (
    <ResponsiveContainer width="95%" height={200}>
      <ComposedChart
        width={800}
        height={200}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />

        <XAxis
          dataKey="from"
          tickFormatter={dateformatter}
          tick={{ fontSize: '12px', fontWeight: 'bold' }}
        />

        <YAxis
          yAxisId="left"
          type="number"
          domain={[0, 'dataMax + 10']}
          orientation="left"
          stroke="#e3661f"
          tick={{ fontSize: '12px' }}
          allowDecimals={false}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#29654f"
          width={90}
          tickFormatter={formatter}
          tick={{ fontSize: '12px' }}
        />

        <Tooltip
          wrapperStyle={{
            backgroundColor: '#1a202c',
            fill: '#cbd5e0',
            fontSize: '14px',
            borderRadius: '5px',
            paddingLeft: '2px',
          }}
          content={<CustomTooltip />}
        />
        <Legend wrapperStyle={{ fontSize: '12px' }} formatter={formatLegend} iconType="square" />
        <Area
          yAxisId="left"
          type="monotone"
          dataKey="quantity"
          name={t.TRANSACTIONS}
          fill="#e3661f"
          stroke="#e3661f"
        />
        <Area
          yAxisId="right"
          type="monotone"
          dataKey="sum"
          name={t.AMOUNT}
          fill="#29654f"
          stroke="#29654f"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DailyChart;
