import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Sector, ResponsiveContainer } from 'recharts';

import { formatMoneyPT } from '../../lib/masks';
import t from '../../lib/translations';

const BLUECOLORS = [
  '#e3661f',
  '#29654f',
  '#20394f',
  '#2c6588',
  '#2b95c3',
  '#00c9ff',
  '#27a2d2',
  '#00C49F',
  '#FFBB28',
  '#004a5b',
  '#458a9c',
  '#008669',
  '#00c686',
];
const renderActiveShape = (props): JSX.Element => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 50) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fontSize="12px"
        alignmentBaseline="text-after-edge"
        allowReorder="yes"
        className="font-semibold"
        textAnchor={textAnchor}
        key={payload.categoryId}
        fill="#333">
        {payload.categoryName}
        {` ${(percent * 100).toFixed(1)}%`}
      </text>

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dx={1}
        dy={15}
        fontSize="12px"
        textAnchor={textAnchor}
        fill="#999">
        {payload.quantity > 0 ? `R$ ${formatMoneyPT(value)}` : ''}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }: any): JSX.Element => {
  if (payload && active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{payload[0].payload.categoryName}</p>
        <p className="label">{`${t.TOTAL}: R$ ${formatMoneyPT(payload[0]?.payload.sum)}`} </p>
        <p className="label">{`${t.TRANSACTIONS}: ${payload[0]?.payload.quantity}`} </p>
      </div>
    );
  }

  return null;
};
const CategoryChart = ({ data }): JSX.Element => {
  const activeIndex: any = Array.from(data?.keys());

  return (
    <ResponsiveContainer width="90%" height={500}>
      <PieChart width={400} height={300} margin={{ top: 0, left: 0, right: 0, bottom: 5 }}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="sum"
          paddingAngle={2}
          minAngle={2}>
          {data &&
            Object.values(data)?.map((categoryId, index) => (
              <Cell fill={BLUECOLORS[index % BLUECOLORS.length]} key={index} />
            ))}
        </Pie>
        <Tooltip
          wrapperStyle={{
            backgroundColor: '#1a202c',
            fill: '#cbd5e0',
            fontSize: '14px',
            borderRadius: '5px',
            paddingLeft: '2px',
          }}
          content={<CustomTooltip />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CategoryChart;
