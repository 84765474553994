/* eslint-disable sonarjs/cognitive-complexity */
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { ExecutionResult, WatchQueryFetchPolicy } from 'apollo-boost';
import moment from 'moment-timezone';
import { NextPage } from 'next';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AuthRoute from '../components/AuthRoute';
import BasicButton from '../components/BasicButton';
import Container from '../components/Container';
import IndicatorContainer from '../components/IndicatorContainer';
import SelectDate, { SelectedDate } from '../components/SelectDate';
import CategoryChart from '../components/charts/CategoryChart';
import ClientChart from '../components/charts/ClientChart';
import DailyChart from '../components/charts/DailyChart';
import MonthlyChart from '../components/charts/MonthlyChart';
import { FormDate } from '../components/filters/FilterFormFragment';
import {
  Query,
  QueryTransactionsArgs,
  TransactionsByCategory,
  TransactionsByFarmer,
} from '../generated/types';
import { LOCAL_SET_FLASH } from '../lib/mutations';
import {
  ADMIN_DASHBOARD,
  ADMIN_TRANSACTIONS_BY_TIME,
  ADMIN_TRANSACTIONS_BY_CATEGORY,
  ADMIN_TRANSACTIONS_BY_FARMER,
} from '../lib/queries';
import t from '../lib/translations';
import { FlashType } from '../lib/types';

const Home: NextPage = () => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network';

  const [getAdminDashboard, { data: dashboardData, loading: dashboardLoading }] = useLazyQuery<
    { readonly adminDashboard: Query['adminDashboard'] },
    QueryTransactionsArgs
  >(ADMIN_DASHBOARD, { fetchPolicy });

  const [getTransactionsByMonth, { data: monthlyData, loading: monthlyLoading }] = useLazyQuery<
    { readonly adminTransactionByTime: Query['adminTransactionByTime'] },
    QueryTransactionsArgs
  >(ADMIN_TRANSACTIONS_BY_TIME, { fetchPolicy });

  const [getTransactionsByDay, { data: dailyData, loading: dailyLoading }] = useLazyQuery<
    { readonly adminTransactionByTime: Query['adminTransactionByTime'] },
    QueryTransactionsArgs
  >(ADMIN_TRANSACTIONS_BY_TIME, { fetchPolicy });

  const [getTransactionsByFarmer, { data: farmerData, loading: farmerLoading }] = useLazyQuery<
    { readonly adminTransactionByFarmer: Query['adminTransactionByFarmer'] },
    QueryTransactionsArgs
  >(ADMIN_TRANSACTIONS_BY_FARMER, { fetchPolicy });

  const [
    getTransactionsByCategory,
    { data: categoryData, loading: categoryLoading },
  ] = useLazyQuery<
    { readonly adminTransactionByCategory: Query['adminTransactionByCategory'] },
    QueryTransactionsArgs
  >(ADMIN_TRANSACTIONS_BY_CATEGORY, { fetchPolicy });

  const [dateData, setDateData] = useState<SelectedDate>();
  const [datesByRange, setDatesByRange] = useState<FormDate>({
    fromDate: moment().startOf('day').subtract(12, 'month').format('YYYY-MM-DD'),
    toDate: moment().endOf('day').format('YYYY-MM-DD'),
  });

  const inputData = [
    {
      name: 'fromDate',
      value: datesByRange.fromDate,
      labelName: t.FROM,
    },
    {
      name: 'toDate',
      value: datesByRange.toDate,
      labelName: t.TO,
    },
  ];

  const indicatorCategories = [
    {
      name: t.NET_PROCEEDS,
      value: dashboardData?.adminDashboard?.grossIncome ?? 0,
      isCurrency: true,
      isNegative: false,
    },
    {
      name: t.GROSS_INCOME,
      value: dashboardData?.adminDashboard?.netProceeds ?? 0,
      isCurrency: true,
      isNegative: false,
    },
    {
      name: t.TRANSACTIONS,
      value: dashboardData?.adminDashboard?.transactionsAmount ?? 0,
      isCurrency: false,
      isNegative: false,
    },

    {
      name: t.AVERAGE_TICKET,
      value: dashboardData?.adminDashboard?.transactionsAmount
        ? dashboardData?.adminDashboard?.grossIncome /
          dashboardData?.adminDashboard?.transactionsAmount
        : 0,
      isCurrency: true,
      isNegative: false,
    },
    {
      name: t.AVERAGE_RATE,
      value: dashboardData?.adminDashboard?.grossIncome
        ? (dashboardData?.adminDashboard?.netProceeds /
            dashboardData?.adminDashboard?.grossIncome) *
          100
        : 0,
      isCurrency: false,
      isPercent: true,
      isNegative: false,
    },
    {
      name: t.REFUND_NOUN,
      value: Math.abs(dashboardData?.adminDashboard?.refundValue ?? 0),
      isCurrency: true,
      isNegative: dashboardData?.adminDashboard?.refundValue < 0,
    },
  ];
  const currentMonth: number = moment().month();
  const startOfMonth = moment().startOf('month').toISOString();
  const endOfMonth = moment().endOf('month').toISOString();
  const startOfSelectedMonth = dateData
    ? moment().year(Number(dateData.year)).month(dateData.month).startOf('month').toISOString()
    : null;
  const endOfSelectedMonth = dateData
    ? moment().year(Number(dateData.year)).month(dateData.month).endOf('month').toISOString()
    : null;

  const [setFlash] = useMutation(LOCAL_SET_FLASH);

  const handleSubmit = (): Promise<ExecutionResult<any>> => {
    if (moment(datesByRange.toDate) < moment(datesByRange.fromDate)) {
      return setFlash({
        variables: { payload: { message: t.error.START_DATE_GREATER }, type: FlashType.info },
      });
    }

    if (datesByRange.fromDate.length === 0 || datesByRange.toDate.length === 0) {
      return setFlash({
        variables: { payload: { message: t.CHOOSE_DATE_RANGE }, type: FlashType.info },
      });
    }

    getAdminDashboard({
      variables: {
        from: moment(datesByRange.fromDate).startOf('day').format(),
        to: moment(datesByRange.toDate).endOf('day').format(),
      },
    });

    getTransactionsByMonth({
      variables: {
        from: moment(datesByRange.fromDate).startOf('day').format(),
        to: moment(datesByRange.toDate).endOf('day').subtract(3, 'hour').format(),
        timeType: 'month',
        timezone: moment.tz.guess(),
      },
    });
  };

  const onSubmit = (): Promise<ExecutionResult<any>> => {
    if (dateData === null) {
      return setFlash({
        variables: { payload: { message: t.CHOOSE_DATE_RANGE }, type: FlashType.info },
      });
    }
    getTransactionsByDay({
      variables: {
        from: startOfSelectedMonth,
        to: endOfSelectedMonth,
        timeType: 'day',
        timezone: moment.tz.guess(),
      },
    });

    getTransactionsByFarmer({
      variables: {
        from: startOfSelectedMonth,
        to: endOfSelectedMonth,
        timezone: moment.tz.guess(),
      },
    });

    getTransactionsByCategory({
      variables: {
        from: startOfSelectedMonth,
        to: endOfSelectedMonth,
      },
    });
  };

  const groupByCategory = categoryData?.adminTransactionByCategory.reduce((result, category) => {
    if (!result[category.categoryId]) {
      // eslint-disable-next-line functional/immutable-data
      result[category.categoryId] = {
        categoryId: category?.categoryId,
        categoryName: category?.categoryName,
        sum: 0,
        quantity: 0,
      };
    }
    // eslint-disable-next-line functional/immutable-data
    result[category.categoryId].sum = result[category.categoryId].sum + category.sum;
    // eslint-disable-next-line functional/immutable-data
    result[category.categoryId].quantity = result[category.categoryId].quantity + category.quantity;

    return result;
  }, {});

  const allCategories = groupByCategory ? Object.values(groupByCategory) : [];

  const filteredCategoryData = allCategories
    ? allCategories?.filter((categoryId: TransactionsByCategory) => categoryId.quantity > 0)
    : [];

  const filteredFarmers = farmerData?.adminTransactionByFarmer
    ? farmerData?.adminTransactionByFarmer
        .filter((farmer: TransactionsByFarmer) => farmer.quantity > 0)
        .slice()
        .sort((top, bottom) => {
          if (bottom.sum > top.sum) {
            return 1;
          }
          return -1;
        })
    : [];

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setDatesByRange(Object.assign({ ...datesByRange }, { [name]: value }));
  };

  useEffect(() => {
    getAdminDashboard({
      variables: {
        from: datesByRange.fromDate,
        to: moment().month(datesByRange.toDate).endOf('day').toISOString(),
      },
    });
  }, []);

  useEffect(() => {
    getTransactionsByMonth({
      variables: {
        from: moment().startOf('month').subtract(12, 'month').toISOString(),
        to: moment().endOf('month').add(1, 'month').toISOString(),
        timeType: 'month',
        timezone: moment.tz.guess(),
      },
    });
  }, []);

  useEffect(() => {
    getTransactionsByDay({
      variables: {
        from: startOfMonth,
        to: endOfMonth,
        timeType: 'day',
        timezone: moment.tz.guess(),
      },
    });
  }, []);

  useEffect(() => {
    getTransactionsByFarmer({
      variables: {
        from: startOfMonth,
        to: endOfMonth,
      },
    });
  }, []);

  useEffect(() => {
    getTransactionsByCategory({
      variables: {
        from: startOfMonth,
        to: endOfMonth,
      },
    });
  }, []);

  return (
    <Container
      head={t.HOME}
      loading={
        monthlyLoading || dashboardLoading || categoryLoading || farmerLoading || dailyLoading
      }>
      <div className="home h-screen w-full flex flex-col">
        <div className="flex flex-col sm:flex-row justify-center align-baseline mt-0 pt-0 ml-2 mb-6">
          {inputData.map((input) => (
            <div key={input.name} className="md:flex md:items-center mr-2">
              <div className="md:w-1/3">
                <label htmlFor={input.name} className="label md:text-right md:mb-0 px-2">
                  {input.labelName}
                </label>
              </div>
              <input
                name={input.name}
                className="date-input"
                type={process.env.NODE_ENV === 'test' ? 'text' : 'date'}
                onChange={onChange}
                value={input.value}
              />
            </div>
          ))}
          <div className="btn-container mt-2 sm:mt-0">
            <BasicButton children={t.SEARCH} onClick={handleSubmit} />
          </div>
        </div>

        <div className="flex flex-row justify-around flex-wrap bg-gray-200 ml-2">
          {indicatorCategories.map((category) => (
            <IndicatorContainer
              indicatorTitle={category.name}
              isCurrency={category.isCurrency}
              isPercent={category.isPercent}
              isNegative={category.isNegative}
              indicatorValue={category.value}
              key={category.name}
            />
          ))}
        </div>
        <div className="bg-gray-200 ml-2">
          <div className="chart">
            <h3 className="chart-title md:text-lg">{t.MONTHLY}</h3>
            <MonthlyChart data={monthlyData?.adminTransactionByTime ?? []} />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center py-1 sm:py-3">
          <SelectDate onChange={setDateData} defaultMonth={currentMonth} />
          <div className="btn-container pl-2 mt-2 sm:mt-0">
            <BasicButton children={t.SEARCH} onClick={onSubmit} />
          </div>
        </div>

        <div>
          <div className="bg-gray-200 ml-2 pt-1">
            <div className="chart">
              <h3 className="chart-title md:text-lg">{t.DAILY}</h3>
              <DailyChart data={dailyData?.adminTransactionByTime ?? []} />
            </div>

            <div className="flex flex-col sm:flex-row">
              <div className="chart w-full sm:w-2/5">
                <h3 className="chart-title md:text-lg">{t.FARMER}</h3>
                <ClientChart data={filteredFarmers} />
              </div>

              <div className="chart w-full sm:w-3/5">
                <h3 className="chart-title md:text-lg">{t.CATEGORIES}</h3>
                <CategoryChart data={filteredCategoryData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AuthRoute(Home);
