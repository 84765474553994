import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';

import t from '../lib/translations';
import Select from './Select';

export type SelectedDate = {
  readonly month: string;
  readonly year: string;
  readonly isApproved?: string;
};

export type SelectDateProps = {
  readonly onChange?: (selectedData: SelectedDate) => any;
  readonly errors?: { readonly [key: string]: Error };
  readonly defaultMonth?: number;
  readonly className?: string;
};

const YEARS = (): readonly string[] => {
  const years = [];
  const dateStart = moment('2020-01-01T00:00:00.000Z');
  const dateEnd = moment();

  // eslint-disable-next-line functional/immutable-data
  years.push(dateEnd.format('YYYY'));

  // eslint-disable-next-line functional/no-loop-statement
  while (dateEnd.diff(dateStart, 'years') > 0) {
    dateEnd.add(-1, 'year');
    // eslint-disable-next-line functional/immutable-data
    years.push(dateEnd.format('YYYY'));
  }

  return years;
};

const SelectDate = ({
  onChange,
  errors,
  defaultMonth,
  className,
}: SelectDateProps): JSX.Element => {
  const [date, setDate] = useState<SelectedDate>({
    month: '',
    year: moment().format('YYYY'),
  });

  const months: readonly string[] = moment.months();
  const years: readonly string[] = YEARS();

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const name = e.target.name;

    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    if (date.month.length > 0 && date.year.length > 0) {
      onChange(date);
    } else {
      onChange(null);
    }
  }, [date]);

  return (
    <>
      <Select
        label="Mês"
        name="month"
        errors={errors}
        onChange={handleChange}
        defaultValue={months[defaultMonth]}
        className={className}>
        <option value="">{t.MONTH_SELECT}</option>

        {months.map((month, index) => (
          <option key={month} value={index}>
            {month}
          </option>
        ))}
      </Select>

      {!className && <div className="mx-1" />}

      <Select
        label="Ano"
        name="year"
        errors={errors}
        onChange={handleChange}
        defaultValue={years[0]}
        className={className}>
        <option value="">{t.YEAR_SELECT}</option>

        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </>
  );
};
export default SelectDate;
