import React from 'react';

import { toCurrencyPT } from '../lib/masks';

type IndicatorContainerProps = {
  readonly indicatorTitle: string;
  readonly indicatorValue: number;
  readonly isCurrency: boolean;
  readonly isPercent?: boolean;
  readonly isNegative: boolean;
};

const IndicatorContainer = ({
  indicatorTitle,
  indicatorValue,
  isCurrency,
  isPercent = false,
  isNegative,
}: IndicatorContainerProps): JSX.Element => {
  return (
    <div className="flex sm:flex-shrink items-center py-3 justify-around">
      <div className="flex flex-col flex-wrap rounded-md w-full md:w-40 sm:w-24 h-24 bg-gray-900">
        <div className="flex md:flex-shrink font-semibold md:text-sm text-sm md:leading-6 leading-4 md:w-40 w-20 h-8 ml-2 mt-2  text-gray-400">
          {indicatorTitle}
        </div>
        <div className="font-bold md:text-lg text-base md:leading-6 w-32 h-8 pt-1 ml-2 text-gray-400">
          {isCurrency && !isNegative && `R$ ${toCurrencyPT(indicatorValue)}`}
          {isCurrency && isNegative && `R$ -${toCurrencyPT(indicatorValue)}`}
          {isPercent && `${indicatorValue.toFixed(2)} %`}
          {!isCurrency && !isPercent && `${indicatorValue}`}
        </div>
      </div>
    </div>
  );
};

export default IndicatorContainer;
